<template>
  <div class="v-big-constructor-images">
    <img
      v-if="appConfig.VueSettingsPreRun.BigConstructorUseDeskPic"
      class="img"
      :src="appConfig.VueSettingsPreRun.BigConstructorDeskPic"
      alt=""
    />
    <arora-loader v-if="loading" />
    <div
      v-else
      v-for="(image, index) in imagesLocal"
      :key="`picture-stack-${index}`"
      class="v-big-constructor-images__image"
    >
      <arora-nuxt-image
        :image="image"
        alt=""
        image-type="ConstructorModifierBig"
      />
    </div>
    <img
      v-if="appConfig.VueSettingsPreRun.BigConstructorUseSteamPic"
      class="img v-steam"
      :src="appConfig.VueSettingsPreRun.BigConstructorSteamPic"
      alt=""
    />
  </div>
</template>

<script setup lang="ts">
import type { ImageInfo } from '~types/common'

import { useCommon } from '@arora/common'

const { images } = defineProps<{ images: ImageInfo[] }>()

const { debounce } = useCommon()
const appConfig = useAppConfig()

const imagesLocal = ref<ImageInfo[]>([...images])
const loading = ref<boolean>(false)

watch(
  () => images,
  (newState: ImageInfo[]) => {
    imagesLocal.value = []
    loading.value = true

    debounce(() => {
      loading.value = false
      imagesLocal.value = [...newState]
    })
  }
)
</script>

<style lang="scss">
@use '~/assets/mixins';

.v-big-constructor-images {
  position: relative;
  min-height: 350px;
  margin-left: 10px;

  @include mixins.md {
    height: 250px;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    &:first-child {
      position: relative;
    }
  }
}

.v-steam {
  animation-iteration-count: infinite;

  animation-name: pulse;

  animation-duration: 1s;
  animation-fill-mode: both;
}
</style>
