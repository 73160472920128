<template>
  <div
    class="v-constructor-options-wrapper"
    data-test-id="product-option"
  >
    <menu-option-selector
      :data-test-id="`${dataTestId}-options`"
      :options="bigConstructor.Options"
      :product="bigConstructor"
      :threshold="appConfig.VueSettingsPreRun.BigConstructorOptionThreshold"
      :use-name="!appConfig.VueSettingsPreRun.BigConstructorUseDesc"
    />
  </div>

  <div class="v-big-constructor-default">
    <div
      v-if="bigConstructor.ConstructorTextAbove"
      class="v-big-constructor-default-row-text-above"
      v-html="bigConstructor.ConstructorTextAbove"
    />
    <div class="v-big-constructor-default-row">
      <div
        class="v-big-constructor-default-categories"
        :data-test-id="`${dataTestId}-categories`"
      >
        <div
          v-for="category in filteredBigConstructorCategories"
          :key="category.ID"
          class="v-big-constructor-default-category"
          :class="{ selected: category.ID === selectedCategory.ID }"
          :data-test-id="`${dataTestId}-category-block`"
          @click="() => selectCategory(category)"
        >
          <common-popover
            :disabled="!isCategoryRequired(category) || isCategoryFilledWithMinimum(category)"
            placement="top"
          >
            <template #hoverable>
              <div class="v-position-relative">
                <icon-selectable
                  v-if="category.Image && category.ImageSelected"
                  :alt="category.Name"
                  :icon="category.Image"
                  :selected="category.ID === selectedCategory.ID"
                  :selected-icon="category.ImageSelected"
                  height="50px"
                  width="95px"
                />
                <span
                  class="v-big-constructor-default-category-name"
                  v-html="sanitize(category.Name)"
                />
                <icon-exclamation-circle
                  v-if="isCategoryRequired(category) && !isCategoryFilledWithMinimum(category)"
                  class="v-position-absolute v-warning-icon v-big-constructor-default-category-warning"
                />
              </div>
            </template>
            <template #content>
              {{ categoryRequireText(category) }}
            </template>
          </common-popover>
        </div>
      </div>
      <div
        class="v-big-constructor-default-category-mods"
        :data-test-id="`${dataTestId}-category-mods`"
      >
        <div
          v-for="mod in selectedCategory.ConstructorCategoryModifiers"
          :key="mod.ID"
          class="v-big-constructor-mod-default-single"
        >
          <mod-default
            :key="mod.ID"
            :amount="
              selectedModsByCategory[mod.CategoryID]
                ? selectedModsByCategory[mod.CategoryID][mod.ID]
                : undefined
            "
            :disabled="
              numberOfAvailableModsByCategory[mod.CategoryID] <= 0 &&
              selectedModsByCategory[mod.CategoryID][mod.ID] === 0
            "
            :data-test-id="dataTestId"
            :mod="mod"
            :onclick="async () => await modClick(mod)"
            :onremove="() => modRemove(mod)"
          />
        </div>
      </div>
      <div
        v-if="selectedOption"
        class="v-big-constructor-default-picture"
      >
        <slot />
      </div>
    </div>
    <div class="v-big-constructor-default-row">
      <div
        class="v-big-constructor-default-ingredients-title"
        v-html="
          bigConstructor.ConstructorBaseText?.length === 0
            ? translate('constructorBigPage.base')
            : sanitize(bigConstructor.ConstructorBaseText)
        "
      />
      <div class="v-big-constructor-default-mods-below">
        <span
          class="v-big-constructor-default-mods-below-mod-name"
          v-html="sanitize(selectedOption?.Name)"
        />
        <div class="v-stepper-counter-wrapper">
          <common-currency :amount="basePay" />
        </div>
      </div>
    </div>

    <div class="v-big-constructor-default-row">
      <div
        class="v-big-constructor-default-ingredients-title"
        v-html="translate('constructorBigPage.ingredients')"
      />

      <TransitionGroup name="list">
        <template
          v-for="cat in bigConstructor?.Categories ?? []"
          :key="cat.ID"
        >
          <template v-if="selectedModsByCategory && selectedModsByCategory[cat.ID]">
            <template
              v-for="mod in cat.ConstructorCategoryModifiers"
              :key="mod.ID"
            >
              <div
                v-if="
                  selectedModsByCategory[cat.ID][mod.ID] && selectedModsByCategory[cat.ID][mod.ID] > 0
                "
                class="v-big-constructor-default-mods-below v-mb-sm"
              >
                <span
                  class="v-big-constructor-default-mods-below-mod-name"
                  v-html="sanitize(mod.Name)"
                />
                <div class="v-stepper-counter-wrapper">
                  <arora-stepper
                    :data-test-id="`${dataTestId}-modifier-stepper-${mod.ID}`"
                    :disable-plus-button="selectedModsByCategory[cat.ID][mod.ID] >= mod.ValueMax"
                    :label="translate('addToCartButton.addToCart')"
                    :on-decrement="() => onDecrement(cat.ID, mod.ID)"
                    :on-increment="() => modClick(mod)"
                    :value="selectedModsByCategory[cat.ID][mod.ID]"
                  />
                </div>
                <common-currency :amount="mod.Price * selectedModsByCategory[cat.ID][mod.ID]" />
                <div
                  class="v-big-constructor-default-mods-below-mod-remove v-d-flex v-justify-content-end"
                >
                  <arora-button
                    class-name="v-btn-square v-square-small"
                    :label="translate('constructorPage.deleteMod')"
                    @click="() => (selectedModsByCategoryLocal[cat.ID][mod.ID] = 0)"
                  >
                    <icon-trashcan />
                  </arora-button>
                </div>
              </div>
            </template>
          </template>
        </template>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ConstructorRequiredSettings } from '~api/consts'

import type { ModifierCounts, ModsByCategory } from '~types/clientStore'
import type { Category, ConstructorCategoryModifier } from '~types/menuStore'
import type { ConstructorBigCommon } from '~types/props'

import type { GUID } from '@arora/common'

const { bigConstructor, selectedOption, selectedModsByCategory } = defineProps<ConstructorBigCommon>()

const appConfig = useAppConfig()
const { translate, sanitize } = useI18nSanitized()

const selectedModsByCategoryLocal = ref<ModsByCategory>(selectedModsByCategory)

const selectedCategory = ref<Category>(bigConstructor.Categories[0])

const basePay = computed<number>(() => selectedOption?.Price ?? 0)

const numberOfAvailableModsByCategory = computed<ModifierCounts>(() => {
  const result: ModifierCounts = {}

  for (const category of bigConstructor?.Categories ?? []) {
    let count = 0
    for (const mod of category.ConstructorCategoryModifiers) {
      if (mod.ProductAttachedTo === selectedOption?.ID || mod.ProductAttachedTo === bigConstructor?.ID) {
        count += selectedModsByCategoryLocal.value[category.ID][mod.ID]
      }
    }

    if (category.EnableMaxLimit && category.IngredientsMax) {
      result[category.ID] = category.IngredientsMax - count
    } else {
      result[category.ID] = 10 - count
    }
  }

  return result
})
const filteredBigConstructorCategories = computed(() => {
  return bigConstructor?.Categories.filter(
    (categories) =>
      categories.ConstructorCategoryModifiers && categories.ConstructorCategoryModifiers.length > 0
  )
})

const modLimitPerCategory = computed<ModifierCounts>(() => {
  const result: ModifierCounts = {}

  for (const category of bigConstructor?.Categories ?? []) {
    if (category.EnableMaxLimit) {
      result[category.ID] = category.IngredientsMax ?? 10
    } else {
      result[category.ID] = 10
    }
  }

  return result
})

async function onDecrement(catId: GUID, modId: GUID): Promise<void> {
  selectedModsByCategoryLocal.value[catId][modId]--
}

function isCategoryRequired(category: Category): boolean {
  return (
    category.TypeRequire === ConstructorRequiredSettings.OneAndMoreRequired ||
    category.TypeRequire === ConstructorRequiredSettings.OneRequired
  )
}

function isCategoryFilledWithMinimum(category: Category): boolean {
  let count = 0

  if (!category.IngredientsMin || !category.IngredientsMax) return true

  for (const mod of category.ConstructorCategoryModifiers) {
    if (
      selectedModsByCategoryLocal.value[category.ID] &&
      selectedModsByCategoryLocal.value[category.ID][mod.ID]
    )
      count += selectedModsByCategoryLocal.value[category.ID][mod.ID]
  }

  return count >= category.IngredientsMin && count <= category.IngredientsMax
}

function selectCategory(category: Category): void {
  selectedCategory.value = category
}

async function modClick(mod: ConstructorCategoryModifier): Promise<void> {
  if (!selectedModsByCategoryLocal.value[mod.CategoryID]) {
    selectedModsByCategoryLocal.value[mod.CategoryID] = {}
  }
  if (
    numberOfAvailableModsByCategory.value[mod.CategoryID] > 0 &&
    selectedModsByCategoryLocal.value[mod.CategoryID][mod.ID] <
      modLimitPerCategory.value[mod.CategoryID] &&
    selectedModsByCategoryLocal.value[mod.CategoryID][mod.ID] < mod.ValueMax
  ) {
    selectedModsByCategoryLocal.value[mod.CategoryID][mod.ID]++
  }
}

function modRemove(mod: ConstructorCategoryModifier): void {
  selectedModsByCategoryLocal.value[mod.CategoryID][mod.ID] = 0
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-big-constructor-default {
  flex: 0 0 100%;
  width: 100%;
}

.v-big-constructor-default-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 15px;
  gap: 10px;

  @include mixins.md {
    padding: 10px 5px;
  }
}

.v-big-constructor-default-row-text-above {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 25px 15px;
}

.v-big-constructor-default-categories {
  flex: 0 0 15%;
  max-width: 15%;

  @include mixins.lg {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.v-big-constructor-default-category-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-big-constructor-default-category-warning {
  top: 0;
  right: 0;
}

.v-big-constructor-default-category {
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  box-shadow: variables.$InputShadow;
  border: variables.$BorderWidth solid variables.$BorderColor;
  border-radius: variables.$BorderRadius;
  text-align: center;

  @include mixins.trans;

  img {
    margin-bottom: 5px;
  }

  &.selected {
    background: variables.$BodyElementsBackground;
    border-width: 0;
  }

  &:hover {
    border-color: variables.$PrimaryBackgroundColor;
  }

  @include mixins.md {
    flex: 0 0 calc(33.33% - 10px);
    max-width: calc(33.33% - 10px);
  }

  @include mixins.sm {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

.v-big-constructor-default-category-mods {
  flex: 0 0 37.5%;
  max-width: 37.5%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: baseline;
  text-align: center;

  background: variables.$BodyElementsBackground;
  box-shadow: variables.$InputShadow;
  border-radius: variables.$BorderRadius;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;

  @include mixins.lg {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.v-big-constructor-mod-default-single {
  flex: 0 0 33.33%;
  max-width: 33.33%;

  padding: 15px;

  @include mixins.sm {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.v-big-constructor-default-picture {
  flex: 0 0 47.5%;
  max-width: 47.5%;

  padding: 15px;

  img {
    max-width: 100%;
    height: auto;
  }

  @include mixins.lg {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.v-big-constructor-default-ingredients-title {
  font-weight: bolder;
  font-size: 22px;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 10px;
}

.v-big-constructor-default-ingredients {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 5px;
}

.v-big-constructor-default-mods-below {
  flex: 0 0 100%;
  max-width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: variables.$BorderWidth solid variables.$BorderColorLight;

  .v-big-constructor-default-mods-below-mod-name {
    flex: 0 0 65%;
    max-width: 65%;
    font-size: 16px;

    @include mixins.md {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }

  .v-currency-wrapper {
    flex: 0 0 15%;
    max-width: 15%;
    font-size: 16px;
    text-align: center;
  }

  .v-stepper-counter-wrapper {
    flex: 0 0 calc(15% - 10px);
    max-width: calc(15% - 10px);
    margin-right: 10px;

    input {
      margin: 0;
    }

    @include mixins.md {
      flex: 0 0 calc(30% - 10px);
      max-width: calc(30% - 10px);
    }
  }

  .v-big-constructor-default-mods-below-mod-remove {
    flex: 0 0 5%;
    max-width: 5%;

    @include mixins.md {
      flex: 0 0 15%;
      max-width: 15%;
    }
  }
}

.v-constructor-options-wrapper {
  flex: 0 0 50%;
  max-width: 50%;
  padding-top: 25px;
  padding-left: 15px;

  @include mixins.md {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
