<template>
  <section>
    <div class="v-container">
      <div
        v-if="productBase"
        class="v-item-page"
      >
        <menu-product-base-page
          v-if="productBase.ProductApiType === ProductApiType.Default"
          :product-id="productBase.ID"
        />
        <menu-product-slots-lunch-page
          v-else-if="productBase.ProductApiType === ProductApiType.Lunch"
          :slot-id="productBase.ID"
        />
        <menu-product-slots-half-page
          v-else-if="productBase.ProductApiType === ProductApiType.Half"
          :slot-id="productBase.ID"
        />
        <menu-product-slots-quarter-page
          v-else-if="productBase.ProductApiType === ProductApiType.Quarter"
          :slot-id="productBase.ID"
        />
        <menu-product-constructor-big-page
          v-else-if="productBase.ProductApiType === ProductApiType.BigConstructor"
          :constructor-id="productBase.ID"
        />
        <menu-product-constructor-card-page
          v-else-if="productBase.ProductApiType === ProductApiType.Constructor"
          :constructor-id="productBase.ID"
        />
      </div>
    </div>
    <div class="v-item-page-extra">
      <lazy-menu-recommended />
      <transition name="fade">
        <div
          v-if="productBase"
          class="v-container"
        >
          <lazy-menu-related :product-id="productBase.ID" />
        </div>
      </transition>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ProductApiType } from '~api/consts'

import type { Product } from '~types/menuStore'

const route = useRoute()
const appConfig = useAppConfig()

const productBase = ref<Product>(appConfig.Products.find((product) => product.ID === route.meta.ID)!)
</script>

<style lang="scss">
.v-item-page {
  margin-bottom: 1.1rem;
}

.v-item-page-extra {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
